<!--
#***********************************************
#
#      Filename: src/views/Report/TmpVehicleReport.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 临停车报表
#        Create: 2021-10-28 16:42:53
# Last Modified: 2021-11-11 17:28:55
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table
      :data="dataList"
      stripe
      border
      size="mini"
      show-summary
      :summary-method="getSummaries"
      @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import moment from 'moment'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list } from '@/api/daily'

  export default {
    name: 'AdminTmpVehicleReport',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '车位号/产权室号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: true,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'checkbox',
                name: 'isSync',
                label: '是否同步'
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '待定',
                options: []
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '日期',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'date'
          },
          {
            label: '区域名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'parkingInfo.communityInfo.name'
          },
          {
            label: '停车场名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'parkingInfo.name'
          },
          {
            label: '应收金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalAmt'
          },
          {
            label: '现金支付（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payAmt'
          },
          {
            label: '线上支付（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'pendingAmt'
          },
          {
            label: '优惠/优免（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'discountAmt'
          },
          {
            label: '手续费（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'commissionFee'
          },
          {
            label: '开票金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'invoiceAmt'
          },
          {
            label: '收费订单数',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'invoiceOrderCount'
          }
        ]
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 总计方法
       */
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        console.log('param', param)
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = '总计'
            return
          }
          if (index === 9 || index === 3 || index === 4 || index === 5 || index === 6 || index === 7 || index === 8) {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              if (index !== 9) {
                sums[index] += ' 元'
              }
            }
          }
        })
        return sums
      },
      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
        if (this.filterData.dateTimeRange) {
          queryData.startDate = moment(this.filterData.dateTimeRange[0]).format('YYYY-MM-DD')
          queryData.endDate = moment(this.filterData.dateTimeRange[1]).format('YYYY-MM-DD')
        }

        list(queryData)
          .then((res) => {
            this.dataList = res.data.records
            this.total = res.total
          })
          .catch((err) => {
            console.error('临停车报表查询出错', err)
          })
          .finally(() => {
            this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      }
    }
  }
</script>
