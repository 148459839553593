/*
#***********************************************
#
#      Filename: src/api/equipment/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 自然日报表
#        Create: 2021-11-17 11:42:45
# Last Modified: 2021-11-26 13:57:44
#***********************************************
*/
import request from '@/utils/request'

// list
export function list(data) {
  return request({
    url: '/report/daily/list',
    method: 'get',
    params: data
  })
}

// 导出记录
export function exportRecord(data) {
  return request({
    url: '/report/daily/export',
    method: 'get',
    params: data
  })
}
